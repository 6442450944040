@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");


/* @font-face {
  font-family: 'mangalfont';
  src: local('mangalfont'), url('../public/font/mangalfont.ttf') format('truetype');
} */
@font-face {
  font-family: "mangalfont";
  src: url("https://demoplus.happyplus.in/images/mangalfont.ttf") format('truetype');
}

.font-face-gm {
  font-family: "mangalfont";
}


.line-clamp-1,
.line-clamp-2,
.line-clamp-3,
.line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

* {
  font-family: 'Poppins', "Open Sans", sans-serif;
}

.scollar-xs-blue::-webkit-scrollbar {
  width: 6px;
  height: 2px;
  cursor: pointer;
}

.scollar-xs-blue::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  background: 0 0;
  cursor: pointer;
}

.scollar-xs-blue::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #c1c1c1;
  border-radius: 10px;
  margin: 2px;
  padding: 2px;
}

.scollar-xs::-webkit-scrollbar {
  width: 4px;
  height: 2px;
  cursor: pointer;
}

.scollar-xs::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  background: 0 0;
  cursor: pointer;
}

.scollar-xs::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: rgb(226 232 240);
  border-radius: 10px;
}

.lang::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
}

.lang::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  background: 0 0;
  cursor: pointer;
}

.lang::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #cfd8dc;
}

.customscroll4::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
}

.customscroll4::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  background: 0 0;
  cursor: pointer;
}

.customscroll4::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #bdbdbd;
}

.customscroll5::-webkit-scrollbar {
  width: 10px;
  cursor: pointer;
}

.customscroll5::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  background: 0 0;
  cursor: pointer;
}

.customscroll5::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #bdbdbd;
}

.customscroll2::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  cursor: pointer;
}

.customscroll2::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  background: 0 0;
  cursor: pointer;
}

.customscroll2::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #bdbdbd;
}

.sidebar-scrollar::-webkit-scrollbar {
  width: 4px;
  height: 5px;
  cursor: pointer;
  border-radius: 50px;
}

.sidebar-scrollar::-webkit-scrollbar-track {
  border-radius: 50px;
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  background: 0 0;
  cursor: pointer;
}

.sidebar-scrollar::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #e1e8f0;
  border-radius: 50px;
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
}

.line-clamp-4 {
  -webkit-line-clamp: 4;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted #000;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 120%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000 transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.extraClass1,
.extraClass2,
.extraClass3,
.extraClass4,
.extraClass5,
.extraClass6 {
  white-space: nowrap !important;
}

.list-circle {
  list-style-type: circle;
}

.tooltipcard {
  display: none;
}

.tooltipshow:hover .tooltipcard {
  display: block;
}

.tooltipcard .tooltipcard::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: red transparent transparent transparent;
}


.no-scrollbar {
  overflow: hidden; /* Prevent scrollbars */
}

/* Hide scrollbar for WebKit browsers (Chrome, Safari) */
.no-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

/* Hide scrollbar for Internet Explorer and Edge */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
}

/* Hide scrollbar for Firefox */
.no-scrollbar {
  scrollbar-width: none; /* Firefox */
}








/* Toggle Btn */
/* Rounded sliders */
/* .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 56px;
    height: 26px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .3s;
    transition: .2s;
  }

  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
   */

input:checked~.dot {
  transform: translateX(125%);
  right: 0.25rem;
  margin-left: 0.25rem;
}

input:checked+.togle-bg {
  background-color: #2196f3;
}

/*circle progress  */



.ql-editor {
  min-height: 80px !important;
  max-height: 150px;
  overflow: hidden;
  overflow-y: scroll;
  /* overflow-x: scroll; */
}





/* Pagiantion Css start */

.pagination {
  @apply items-center flex
}


.pagination>li>a,
.pagination>li>span {
  @apply relative inline-flex items-center px-3 py-1.5 text-sm font-medium text-gray-700 cursor-pointer border border-gray-200 bg-white
}

/* .pagination>li:nth-child(2n + 1)>a,
.pagination>li:nth-child(2n + 1)>span {
  @apply bg-opacity-5
} */

.pagination>li.active>a {
  @apply bg-[#FFFFFF] text-[#2196F3]
}

/* Style the active class (and buttons on mouse-over) */
.pagination>li>a:hover {
  @apply bg-[#FFFFFF] text-[#2196F3]
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
  @apply rounded-l block
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
  @apply rounded-r block mr-0
}

.toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #68D391;
}

.toggle-checkbox:checked+.toggle-label {
  @apply: bg-green-400;
  background-color: #68D391;
}

/* Pagiantion Css start */

.writing-mode{
  writing-mode: vertical-rl;
}